import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from './pages/Home'
import AddressSelect from './pages/AddressSelect'
import ShowInternet from "./pages/ShowInternet";

function App() {
  return (
    <Router>
        <Switch>
          <Route path="/internet">
            <ShowInternet />
          </Route>
          <Route path="/pick-address">
            <AddressSelect />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
