import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { Base64 } from 'js-base64'
import { ActionButton } from "@fluentui/react"

const RedirectIfAlreadyExisting = (j, set, hist, look, post) => {
    if(j.hasOwnProperty('TelephoneNumber'))
    {
        look.append('postcode', post)
        hist.push(`/internet?lookup=${Base64.encode(look)}`)
    }
    else{
        set(j)
    }
}

const FetchStuff = (location, setAddressInfo, hist) => {
    const urlTing = new URLSearchParams(location.search)
    const postcode = urlTing.get('postcode')
    urlTing.delete('postcode')

    fetch(`https://broadband.doworry.icu/address/${postcode}?${urlTing}`).then(r => r.json()).then(j => RedirectIfAlreadyExisting(j,setAddressInfo, hist, urlTing, postcode))
}

const clickLink = (addy, hist) => {
    //console.log(addy)

    const data = JSON.stringify({
        PostCode: addy.BritishAddress.PostCode,
        Town: `${addy.BritishAddress.PostTown} ${addy.BritishAddress.PostCode}`,
        Refnumber: addy.Address.RefNumber,
        Street: addy.BritishAddress.ThoroughfareName,
        BuildingNumber: addy.BritishAddress.ThoroughfareName, // todo: check that this might be a number at any point
        BuildingName: `${addy.BritishAddress.SubPremisesName} ${addy.BritishAddress.PremisesName} (Location:${addy.Address.Location})`,
        DistrictId: addy.Address.DistrictCode,
    })

    hist.push(`/internet?data=${Base64.encode(data)}`)
}

const wifiEthernetIcon = { iconName: 'WifiEthernet' }


const AddressSelect = () => {
    const [addressInfo, setAddressInfo] = useState()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        FetchStuff(location, setAddressInfo, history);
    }, [location, setAddressInfo, history])

    if(!addressInfo){
        return (
            <p>Loading...</p>
        )
    }

    //console.log(addressInfo)

    return (
        <>
            {
                addressInfo.AddressObjectList.map(addy => 
                    (
                        <ul>
                            {addy.BritishAddress.ThoroughfareNumber ? 
                            <li><ActionButton iconProps={wifiEthernetIcon} onClick={() => clickLink(addy, history)}>{addy.BritishAddress.SubPremisesName} {addy.BritishAddress.ThoroughfareNumber} {addy.BritishAddress.ThoroughfareName}, {addy.BritishAddress.PostCode} {addy.BritishAddress.PostTown}</ActionButton></li>
                             : 
                             <li><ActionButton iconProps={wifiEthernetIcon} onClick={() => clickLink(addy, history)}>{addy.BritishAddress.SubPremisesName},{addy.BritishAddress.PremisesName}, {addy.BritishAddress.ThoroughfareName}, {addy.BritishAddress.PostCode} {addy.BritishAddress.PostTown}</ActionButton></li>
                             }
                        </ul>
                    )
                )
            }
        </>
    )
}

export default AddressSelect
