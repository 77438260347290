import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { Base64 } from 'js-base64'

const GetData = (setData, location) => {
    const frick = new URLSearchParams(location.search)

    if(frick.has('data'))
    {
        const data = Base64.decode(frick.get('data'))
        fetch('https://broadband.doworry.icu/lookup/full', {
            method: 'POST',
            body: data
        }).then(r => r.json()).then(d => setData(d))
    }
    else if(frick.has('lookup'))
    {
        const data = new URLSearchParams(Base64.decode(frick.get('lookup')))
        const postcode = data.get('postcode')
        data.delete('postcode')
        fetch(`https://broadband.doworry.icu/address/${postcode}?${data}`).then(r => r.json()).then(d => setData(d))
    }else if(frick.has('uprn')) {
        fetch(`https://broadband.doworry.icu/lookup/uprn/${frick.get('uprn')}`).then(r => r.json()).then(d => setData(d))
    }else if(frick.has('phone')) {
        fetch(`https://broadband.doworry.icu/lookup/telno/${frick.get('phone')}`).then(r => r.json()).then(d => setData(d))
    }else if(frick.has('alid')) {
        fetch(`https://broadband.doworry.icu/lookup/alid/${frick.get('alid')}`).then(r => r.json()).then(d => setData(d))
    }
}

const ShowInternet = () => {
    const [internetData, setInternetData] = useState()
    const location = useLocation()

    useEffect(() => {
        GetData(setInternetData, location)
    }, [setInternetData, location])

    if(!internetData)
    {
        return (<p>Loading...</p>)
    }
    //console.log(internetData)
    return (
        <>
            <p>{internetData.BuildingName}, {internetData.BuildingNumber}, {internetData.Town}</p>
            <p>VDSL Range A</p>
            <p>Available: {internetData.FeaturedProductTable.VdslRangeAWBCFttcAvailDate} Max UP Speed: {internetData.FeaturedProductTable.VdslRangeAHighUpStreamSpeed} Max DN Speed: {internetData.FeaturedProductTable.VdslRangeAHighDnStreamSpeed} 
             Low UP Speed: {internetData.FeaturedProductTable.VdslRangeALowUpStreamSpeed} Low DN Speed: {internetData.FeaturedProductTable.VdslRangeALowDnStreamSpeed} DN Threshold: {internetData.FeaturedProductTable.VdslRangeADnstreamThreshold} </p>
            <p>VDSL Range B</p>
            <p>Available: {internetData.FeaturedProductTable.VdslRangeBWBCFttcAvailDate} Max UP Speed: {internetData.FeaturedProductTable.VdslRangeBHighUpStreamSpeed} Max DN Speed: {internetData.FeaturedProductTable.VdslRangeBHighDnStreamSpeed} 
             Low UP Speed: {internetData.FeaturedProductTable.VdslRangeBLowUpStreamSpeed} Low DN Speed: {internetData.FeaturedProductTable.VdslRangeBLowDnStreamSpeed} DN Threshold: {internetData.FeaturedProductTable.VdslRangeABnstreamThreshold} </p>
            <p>ADSL2 Annex M</p>
            <p>
                Available: {internetData.AdslTableFlag.Adsl2AnnexMAvailDate}, DN Speed: {internetData.AdslTableFlag.Adsl2AnnexMDnStreamSpeed}, DN Range: {internetData.AdslTableFlag.Adsl2AnnexMDnStreamRange}, UP Speed: {internetData.AdslTableFlag.Adsl2AnnexMUpStreamSpeed}, UP Range: {internetData.AdslTableFlag.Adsl2AnnexMUpStreamRange}
            </p>
            <p>ADSL2</p>
            <p>
                Available: {internetData.AdslTableFlag.Adsl2AvailDate}, DN Speed: {internetData.AdslTableFlag.Adsl2DnStreamSpeed}, DN Range: {internetData.AdslTableFlag.Adsl2DnStreamRange}, UP Speed: {internetData.AdslTableFlag.Adsl2UpStreamSpeed}, UP Range: {internetData.AdslTableFlag.Adsl2UpStreamRange}
            </p>
            <p>ADSL MAX</p>
            <p>
                Available: {internetData.AdslTableFlag.AdslMaxAvailDate}, DN Speed: {internetData.AdslTableFlag.AdslMaxDnStreamSpeed}, DN Range: {internetData.AdslTableFlag.AdslMaxDnStreamRange}, UP Speed: {internetData.AdslTableFlag.AdslMaxUpStreamSpeed}, UP Range: {internetData.AdslTableFlag.AdslMaxUpStreamRange}
            </p>

        </>
    )
}

export default ShowInternet