import React from 'react'
import { isValid, parse } from 'postcode'
import { useHistory } from 'react-router-dom'
import { TextField } from '@fluentui/react'
import  styled from 'styled-components'

const checkPostCode = (str) => {
    if(isValid(str))
        return ""
    return "Invalid Post Code!"
}

const submitForm = (e, history) => {
    //check everything
    e.preventDefault()
    console.log(parse(e.target.postcode.value).postcode)
    const urlParam = new URLSearchParams()
    urlParam.append('postcode', parse(e.target.postcode.value).postcode)
    urlParam.append('buildingNumber', e.target.buildingNumber.value)
    urlParam.append('buildingName', e.target.buildingName.value)
    urlParam.append('street', e.target.street.value)
    urlParam.append('town', e.target.town.value)
    history.push(`/pick-address?${urlParam}`)
}
const MyForm = styled.form`
border: 1px solid black;
padding: 10px;
`

const AddressForm = () => {
    const history = useHistory()
    return (
        <MyForm onSubmit={(e) => {submitForm(e, history)}}>
            <br/>
            <TextField label="Post Code" id="postcode" onGetErrorMessage={checkPostCode} required/>
            <br/>
            <TextField label="Building Number" id="buildingNumber" type="number"/>
            <TextField label="Building Name" id="buildingName" type="text" />
            <br/>
            <TextField label="Street" id="street" type="text" />
            <TextField label="Town" id="town" type="text" />
            <button>submit</button>
        </MyForm>
    )
}

export default AddressForm