import React from 'react'
import { useHistory } from 'react-router'
import { TextField } from '@fluentui/react'

const submitTelno = (e, hist) => {
    hist.push(`/internet?phone=${e.target.telno.value}`)
}

const submitUprn = (e, hist) => {
    hist.push(`/internet?uprn=${e.target.uprn.value}`)
}

const sumbitAlId = (e, hist) => {
    hist.push(`/internet?alid=${e.target.alid.value}`)
}

const IndividualForm = () => {
    const hist = useHistory()
    return (
        <>
        <form onSubmit={(e) => { submitTelno(e,hist)}}>
            <TextField id="telno" label="Telephone Number" type="telephone"/>
        </form>
        <form onSubmit={(e) => { submitUprn(e,hist)}}>
            <TextField id="uprn" label="UPRN" type="number"/>
        </form>
        <form onSubmit={(e) => { sumbitAlId(e,hist)}}>
            <TextField id="alid" label="Access Line ID" type="number"/>
        </form>
        </>
    )
}

export default IndividualForm