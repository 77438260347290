import Ady from '../components/AddressForm'
import Indy from '../components/IndividualForm'
import { Text } from '@fluentui/react'

const Home = () => {
    return (
    <>
        <Text variant="large">Find by address form</Text>
        <Ady/>
        <Text variant="large">Or lookup by default thing</Text>
        <Indy/>
    </>
    )
}

export default Home
